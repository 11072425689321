<template>
  <UModal
    :ui="{
      width,
    }"
  >
    <div id="hub-modal-card">
      <div class="flex justify-between">
        <h3 class="mb-4 ml-6 mt-6 flex items-center justify-start text-base font-semibold text-purple dark:text-white">
          <UIcon
            v-if="icon"
            :name="icon"
            class="mr-2 !size-5"
          />
          {{ translateTitle ? $t(title) : title }}
        </h3>

        <UIcon
          name="i-mdi-close"
          class="m-4 size-6 cursor-pointer rounded-full p-1 hover:bg-trublue-100"
          color="gray"
          @click="cancel"
        />
      </div>

      <UDivider
        v-if="headerBorder"
        class="px-5"
      />

      <div :class="contentPadding ? 'p-6' : ''">
        <!-- Content -->
        <slot />
      </div>

      <UDivider
        v-if="footerBorder"
        class="px-5"
      />

      <slot name="footer">
        <div class="flex items-center justify-end px-6 py-4">
          <HubButton
            :type="cancelButtonType"
            size="xs"
            class="mr-2"
            :label="$t(cancelText)"
            @click="cancel"
          />
          <HubButton
            v-if="showConfirmButton"
            :type="confirmButtonType"
            :color="confirmButtonColor"
            :label="$t(confirmText)"
            size="xs"
            v-bind="confirmButtonAttrs"
            @click="confirmClick"
          />
        </div>
      </slot>
    </div>
  </UModal>
</template>

<script setup lang="ts">
import type { HubButtonType, HubButtonColours } from './HubButton.vue'

const props = withDefaults(
  defineProps<{
    title: string
    icon?: string
    confirmText?: string
    confirmButtonType?: HubButtonType
    cancelText?: string
    cancelButtonType?: HubButtonType
    confirmButtonColor?: HubButtonColours
    confirmButtonAttrs?: object
    showConfirmButton?: boolean
    translateTitle?: boolean
    headerBorder?: boolean
    footerBorder?: boolean
    contentPadding?: boolean
    width?: string
    preventCloseOnConfirm?: boolean
  }>(),
  {
    icon: undefined,
    confirmText: 'confirm',
    cancelText: 'cancel',
    translateTitle: true,
    headerBorder: true,
    footerBorder: true,
    contentPadding: true,
    width: 'w-[calc(100%-2rem)] md:w-[calc(100%-5rem)] lg:max-w-[1000px]',
    cancelButtonType: 'outlined',
    confirmButtonAttrs: undefined,
    confirmButtonType: undefined,
    confirmButtonColor: 'default',
    showConfirmButton: true,
    preventCloseOnConfirm: false
  }
)

const emit = defineEmits<{
  (e: 'update:modelValue', arg1: boolean): void
  (e: 'close' | 'confirm' | 'cancel'): void
}>()

function confirmClick() {
  if (!props.preventCloseOnConfirm) {
    emit('update:modelValue', false)
    emit('close')
  }
  emit('confirm')
}

function cancel() {
  emit('update:modelValue', false)
  emit('close')
  emit('cancel')
}
</script>
